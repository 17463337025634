import h from 'hyperscript';
import classNames from 'classnames';
import generateIcon from '../../_particles/icon/generate-icon';

export default function iconButton({
  tag, type, title = false, icon, link,
  disabled = false, classes = false, tabindex = '0', ariaHidden = false, secondary = false,
}) {
  return h(`${(link && 'a') || tag || 'button'}`, {
    className: classNames(
      'icon-button',
      secondary && 'icon-button--secondary',
      classes && classes,
    ),
    type: type || (!link && 'button'),
    href: link,
    disabled,
    attrs: {
      tabindex,
      'aria-hidden': ariaHidden,
    },
  }, generateIcon({ title, icon, classes: ['icon-button__icon'] }));
}

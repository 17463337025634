import { lory } from '@rsm/allfarblori';
import Slider from 'javascripts/globals/slider';

export default class Gallery {
  constructor($el, options) {
    this.$el = $el;
    this.$slidesFrame = this.$el.querySelector('.gallery__slides-frame');
    this.$slidesContainer = this.$el.querySelector('.gallery__slider');
    this.$slides = this.$el.querySelectorAll('.gallery__slider-item');
    this.$galleryButton = this.$el.querySelector('.gallery__button');
    this.isSlider = this.$el.classList.contains('gallery--slider');

    // Bring in the options
    this.options = {
      ...options,
    };

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.constructGallery();
  }

  deconstructor() {
    if (this.lory) {
      this.lory.destroy();
    }
  }

  constructGallery() {
    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('slider--initialized');
      if (this.$galleryButton) {
        this.$galleryButtonText = this.$galleryButton.querySelector('.button__text');
        this.$galleryButtonText.innerHTML = `${this.$slides.length} Bilder`;
      }
    });

    this.$el.addEventListener('on.lory.resize', () => {
      this.positionGalleryButton();
    });

    this.lory = lory(this.$el, {
      rewind: true,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: 'gallery__slides-frame',
      classNameSlideContainer: 'gallery__slider',
    });

    this.positionGalleryButton();
  }

  positionGalleryButton() {
    if (!this.isSlider) {
      const $activeItemMedia = this.$el.querySelector('.image');
      const activeMediaHeight = $activeItemMedia.offsetHeight;
      const buttonHeight = this.$galleryButton.offsetHeight;
      this.$galleryButton.style.top = `${activeMediaHeight - buttonHeight}px`;
    }
  }
}

// Trigger Gallery by Breakpoint
const mql = [
  window.matchMedia('(min-width: 480px)'),
  window.matchMedia('(min-width: 768px)'),
  window.matchMedia('(min-width: 1203px)'),
];

let componentStore = [];

export function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];

  document
    .querySelectorAll('.gallery')
    .forEach(($gallery) => {
      if (!$gallery.classList.contains('gallery--slider')) {
        componentStore.push(new Gallery($gallery, {
          sizerTarget: 'figure__media',
        }));

        $gallery.querySelector('.gallery__button').addEventListener('click', () => {
          const $lightboxEl = document.querySelector('.gallery__slider-item .js-lightbox');
          const evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          });
          $lightboxEl.dispatchEvent(evt);
        });
      } else {
        const $items = $gallery.querySelectorAll('.gallery__slider-item');
        if ($items && ($items.length < 2)) {
          return;
        }

        componentStore.push(new Slider($gallery, {
          rewind: true,
          infinite: 1,
          sliderFrame: 'gallery__slides-frame',
          slidesContainer: 'gallery__slider',
          sliderSlide: 'gallery__slider-item',
          sliderControlsframe: 'gallery__controls',
          sliderControlsframemethod: 'append',
          slidesToScroll: 1,
          slidesFocusable: false,
          autoplay: true,
          ease: 'cubic-bezier(.75,.28,.29,.7)',
          slideSpeed: 3000,
        }));
      }
    });
}

mql.forEach(((el) => {
  el.addListener(onMediaQueryChange);
}));

document.addEventListener('DOMContentLoaded', () => {
  onMediaQueryChange();
}, false);
